import ApiService from "@/common/api/api.service";

const StatusService = {
  formatarRetorno(retorno){
    
    for (let i = 0; i < retorno.length; i++) {
      retorno[i][retorno[i].key] = retorno[i].value;
    }

    return retorno;
  },
  async autenticacao() {
    let retorno = await ApiService.get(`status/autenticacao`);

    return this.formatarRetorno(retorno.data.data) ;
  },
  async operacoes() {
    let retorno = await ApiService.get(`status/operacoes`);
    return this.formatarRetorno(retorno.data.data);
  },
  async auditoria() {
    let retorno = await ApiService.get(`status/auditoria`);
    return this.formatarRetorno(retorno.data.data);
  },
  email() {
    return ApiService.get(`status/email`);
  },
};

export default StatusService;
