<template>
  <div>
    <b-card v-if="aut == 'admin@lyncas.net'" no-body class="mb-4">
      {{ envUrl }}
      <br/>
      <br/>
      <h4>Operações</h4>
      <b-table
        head-variant="light"
        hover
        :items="items"
        :fields="campos"
        :current-page="currentPage"
        :per-page="perPage"
      >
      </b-table>
      <br/>
      <h4>Auditoria</h4>
      <b-table
        head-variant="light"
        hover
        :items="itemsAud"
        :fields="camposAud"
        :current-page="currentPage"
        :per-page="perPage"
      >
      </b-table>
      <br/>
      <h4>Autenticacao</h4>
      <b-table
        head-variant="light"
        hover
        :items="itemsAut"
        :fields="camposAut"
        :current-page="currentPage"
        :per-page="perPage"
      >
      </b-table>
    </b-card>
  </div>
</template>

<script>
import StatusService from "@/common/services/status/status.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
// import helpers from "@/common/utils/helpers";
const { VUE_APP_API } = process.env;

export default {
  name: "Index",
  data() {
    return {
      campos: [],
      items: [],
      camposAud: [],
      itemsAud: [],
      camposAut: [],
      itemsAut: [],
      urlEnv: VUE_APP_API,
      totalRows: 1000,
      currentPage: 1,
      perPage: 1000,
      pageOptions: [
        5,
        10,
        15,
        { value: 100, text: this.$t("GERAL.PAGINACAO") },
      ],
    };
  },
  mounted() {
    this.operacoes();
    this.auditoria();
    this.autenticacao();
    if(localStorage.usuario_nome != "admin@lyncas.net"){
      this.$router.push({ name: "operacoes" })
    }
  },

  methods: {
    operacoes() {
      this.$store.dispatch(START_LOADING);
      StatusService.operacoes()
        .then((result) => {
          this.campos = result;
          let data = {};

          for (let i = 0; i < result.length; i++) {
            if(!data[result[i].key]) data[result[i].key] = {}

            data[result[i].key] = result[i][result[i].key]

          }

          this.items.push(data);
        })
        .catch((err) => {
           console.log('err', err)
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });

    },
    auditoria() {
      this.$store.dispatch(START_LOADING);
      StatusService.auditoria()
        .then((result) => {
          this.camposAud = result;
          let data = {};

          for (let i = 0; i < result.length; i++) {
            if(!data[result[i].key]) data[result[i].key] = {}

            data[result[i].key] = result[i][result[i].key]

          }

          this.itemsAud.push(data);
        })
        .catch((err) => {
           console.log('err', err)
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });

    },
    autenticacao() {
      this.$store.dispatch(START_LOADING);
      StatusService.autenticacao()
        .then((result) => {
          this.camposAut = result;
          let data = {};

          for (let i = 0; i < result.length; i++) {
            if(!data[result[i].key]) data[result[i].key] = {}

            data[result[i].key] = result[i][result[i].key]

          }

          this.itemsAut.push(data);
        })
        .catch((err) => {
           console.log('err', err)
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });

    },
  },
  computed: {
    aut(){
      return localStorage.usuario_nome;
    },
    envUrl() {
      return VUE_APP_API;
    },
  },
};
</script>
